import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useEffect, useState } from "react";
import { useGetDailyReportQuery } from "./DailyReportSlice";
import { useNavigate } from "react-router-dom";

const DailyReportList = () => {
    const navigate = useNavigate();
    const [searchDailyReport, setSearchDailyReport] = useState({})
    const [filterData, setFilterData] = useState({})
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })
    useEffect(() => {
        localStorage.setItem('dailyReportPage', page);
    }, [page]);

    const {
        data: dailyreportlist,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDailyReportQuery({ page, searchDailyReport })

    let totalPages = 1;
    let totalCollection = 0;

    if (dailyreportlist && dailyreportlist.entities) {
        totalPages = dailyreportlist.totalPage;
    }

    const pageMin = () => setPage((prev) => Math.max(prev - 1, 1));
    const pageAdd = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const firstPage = () => setPage(1);
    const lastPage = () => setPage(totalPages);

    const changeSearchHandle = (e) => {
        e.preventDefault();
        setFilterData({ ...filterData, [e.target.name]: e.target.value })
    }
    const searchHandle = () => {
        setSearchDailyReport(filterData)
    }

    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title">Daily Report </h3>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />
                        </ul>
                    </div>
                </div>

                <div className="search_button mb-4 w-100 d-flex justify-content-center gap-1">
                    <input className="form-select w-25" name="start_date" type="date" onChange={changeSearchHandle} placeholder="Start Date" />
                    <input className="form-select w-25" name="end_date" type="date" onChange={changeSearchHandle} placeholder="End Date" />
                    <input className="form-select w-25" name="prp_name" type="text" onChange={changeSearchHandle} placeholder="Property Name" />
                    <select className="form-select w-25" name="is_collected" onChange={changeSearchHandle}>
                        <option value=''>Select Collected</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                    </select>
                    <select className="form-select w-25" name="is_segregate" onChange={changeSearchHandle}>
                        <option value=''>Select Segregate</option>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                    </select>
                    <button className="btn btn-secondary bg-left-navabr " onClick={searchHandle} >Search</button>
                </div>
                <div className="card card-dark sidebarhome" style={{ height: '58vh' }}>
                    <div className="table-border-style" >
                        <div className="table-responsive"  >
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">House Number</th>
                                        <th scope="col">Street</th>
                                        <th scope="col">Waste Collected</th>
                                        <th scope="col">Waste Category</th>
                                        <th scope="col">Segregated</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {dailyreportlist?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                {/* <tr key={index} className="listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportdetail/${e.id}/${page}/${index}`, { state: e }) }}> */}
                                                <tr key={index} className="listData text-left fw-normal" onClick={() => { navigate(`/b2g/dailyreportdetail`, { state: e }) }}>
                                                    <td>{index + 1 * (page * 10 - 9)}</td>
                                                    <td>{e.house_number}</td>
                                                    <td>{e.street}</td>
                                                    <td>{e.waste_provided == 1 ? "Yes" : "NO"}</td>
                                                    <td>{JSON.parse(e.waste_category).map((e1) => {
                                                        return <span>{`${e1} `}</span>
                                                    })}</td>
                                                    <td>{e.is_segregate == 1 ? "Yes" : "No"}</td>
                                                </tr>
                                            </>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="pagination_box fixed-bottom mt-3 mb-3">
                    <div className=" d-flex justify-content-center">
                        <div className="pagination">
                            <button className="page-link" onClick={firstPage}>
                                1
                            </button>
                            <button className="page-link d-flex" disabled={page <= 1} onClick={pageMin}>
                                &laquo; Previous
                            </button>
                            <div className="page-link">{page}</div>
                            <button className="page-link" disabled={page >= totalPages} onClick={pageAdd}>
                                Next &raquo;
                            </button>
                            <button className="page-link" onClick={lastPage}>
                                {totalPages}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DailyReportList;