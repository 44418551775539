import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SupervisorExcerpt from "./SupervisorExcerpt";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import AddSupervisorOffCanavas from "./addSupervisor";
import { useGetSupervisorQuery } from "./supervisorSlice";
const Supervisors = () => {
    const {
        data: supervisors,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetSupervisorQuery('getSupervisor')


    const navigate = useNavigate()
    if (isError && error.message === '401') navigate('/b2g:login')

    const [filterSearch, setFilterSearch] = useState({})
    const [filterSupervisor, setFilterSupervisor] = useState()

    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }

    const filterSearchData = () => {
        const filteredData = supervisors?.ids?.filter((supervisor_id) => {
            const supervisorEntity = supervisors?.entities[supervisor_id];
            return (
                (!filterSearch?.ward_number || supervisorEntity?.ward_number?.includes(filterSearch?.ward_number)) &&
                (!filterSearch?.number || supervisorEntity?.number?.includes(filterSearch?.number)) &&
                (!filterSearch?.name || supervisorEntity?.name?.toLowerCase().includes(filterSearch?.name?.toLowerCase()))
            );
        });
        setFilterSupervisor(filteredData);
    }



    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="d-flex justify-content-between">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title heading_text">B2g Supervisor</h3>
                        <ul className="breadcrumb">
                            {/* <B2GBreadcrumb /> */}
                            <li className="breadcrumb-item">
                                {/* <span className="breadcrumb-active">B2g Admin</span> */}
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-right">
                        <AddSupervisorOffCanavas />
                    </div>
                </div>

                <div className="search_button w-100 d-flex justify-content-center gap-5">
                    <input className="form-select w-25" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                    <input className="form-select w-25" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                    <input className="form-select w-25" onChange={searchHandle} name="ward_number" type="name" placeholder="Search Ward Number" />
                    <button className="bg-left-navabr  text-light  btn btn-secondary" onClick={filterSearchData} >Data Search</button>
                </div>

                <div className="card card-dark mt-4 sidebarhome">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> Number</th>
                                        <th scope="col">Ward  Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filterSupervisor?.map((supervisorid, index) => {
                                            return <SupervisorExcerpt key={index} supervisorid={supervisorid} index={index} />
                                        })
                                    }
                                    {filterSupervisor == null &&
                                        supervisors?.ids?.map((supervisorid, index) => {
                                            return <SupervisorExcerpt key={index} supervisorid={supervisorid} index={index} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <SpinnerForPage />}

        </>
    )
}

export default Supervisors;