import { json, useLocation, useParams } from "react-router-dom";
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import { useGetDailyReportQuery } from "./DailyReportSlice";
import { useState, useEffect } from "react";

const DailyReportDetail = () => {
    const iec = useLocation().state;
    console.log(iec, "location", "location");
    const [abc, setAbc] = useState([]);
    const [rotationState, setRotationState] = useState({});
    const [rotatecount, setrotatecount] = useState(0)

    useEffect(() => {

        if (iec && iec.image_urls) {
            try {
                const parsedImageUrls = JSON.parse(iec.image_urls);
                setAbc(parsedImageUrls);
            } catch (error) {
                console.error("Error parsing image_urls:", error);
            }
        }
    }, [iec]);

    const imgZoomToggle = (e) => {
        const allImages = document.querySelectorAll('.mix-empty-img');
        const clickedElement = e.currentTarget;
        const isAlreadyEnlarged = clickedElement.classList.contains('mix-empty-img-enlar');


        allImages.forEach((img) => {
            img.classList.remove('mix-empty-img-enlar');
            img.style.transform = 'none';
        });

        if (!isAlreadyEnlarged) {
            clickedElement.classList.add('mix-empty-img-enlar');

            const rotation = rotationState[clickedElement.id] || 0;
            clickedElement.style.transform = `rotate(${rotation}deg) scale(2)`;


        } else {
            setRotationState({ [clickedElement.id]: 0 })
            setrotatecount(0)
        }
    };

    const rotateImage = (e, imgId) => {

        const image = document.getElementById(imgId);
        if (image && image.classList.contains('mix-empty-img-enlar')) {
            let currentRotation = 0
            currentRotation = rotationState[imgId] || 0;
            const newRotation = currentRotation + 90;

            setRotationState(prevState => ({
                ...prevState,
                [imgId]: newRotation
            }));
            if (rotatecount % 2 == 0) {
                image.style.transform = `rotate(${newRotation}deg) scale(2,4)`;

            } else {
                image.style.transform = `rotate(${newRotation}deg) scale(2)`;

            }
            setrotatecount(rotatecount + 1)
        }
    };

    return (
        <>
            <div className="container-fluid mt-5 sidebarhome" style={{ height: '100vh', paddingBottom: '150px' }}>
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h6 className="page-title">Daily Report Person Details</h6>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />
                        </ul>
                    </div>
                </div>
                <div className="card mb-3 rounded card-dark w-100 p-4" style={{ borderRadius: '20px' }}>
                    <h3 className="mb-3">Daily Report Person Details</h3>
                    <div className="row">
                        <h6 className="col-md-4 mb-4">House Number : {iec?.house_number}</h6>
                        <h6 className="col-md-4 mb-4">Full Address : {iec?.full_address}</h6>
                        <h6 className="col-md-4 mb-4">Street : {iec?.street}</h6>
                        {/* {(iec?.vehicle_number == (null || '') && <h6 className="col-md-4 mb-4 ">Vehicle Number : NA </h6>) || <h6 className="col-md-4 mb-4">Vehicle Number : {iec?.user_identifiers}</h6>} */}
                        <h6 className="col-md-4 mb-4">Total Members : {iec?.total_members}</h6>
                        <h6 className="col-md-4 mb-4">Street : {iec?.street}</h6>
                    </div>
                </div>
                <div className="card mt-3 rounded card-dark w-100 p-4" style={{ borderRadius: '20px' }}>

                    <table className="table table-borderless">
                        <tr>
                            {iec?.images?.split(',').map((e) => {
                                return <td key={e} className="col-md-4 mb-4">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        <img onClick={imgZoomToggle} id={`docImg${e + 1}`} className="mix-empty-img" style={{ height: '200px', width: '200px' }} src={`https://apis.ecowrap.in/${e}`} />
                                        <button className="rotate-button btn-dark" onClick={(event) => rotateImage(event, `docImg${e + 1}`)} > Rotate Image </button>
                                    </div>
                                </td>
                            })}
                        </tr>
                        <tr>
                            {iec?.rating?.split(",").map((e) => {
                                return <td key={e} className="col-md-4 mb-4">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        Rating : {e}
                                    </div>
                                </td>
                            })}
                        </tr>
                        <tr>
                            {iec?.response?.split("}").map((e) => {
                                return <td key={e} className="col-md-4 mb-4 ">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        {e.split(/[{},"]/)}
                                    </div>
                                </td>

                            })}
                        </tr>
                        {/* <tr>
                            { typeof(iec?.response)==null?iec?.response.split("}").map((e) => {
                                return <td key={e} className="col-md-4 mb-4 ">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        {e.split(/[{},"]/)}
                                    </div>
                                </td>

                            }):" "}
                        </tr> */}
                        {/* <tr>
                            {iec?.response.split(',').map((e) => {
                                return <td key={e} className="col-md-4 mb-4 ">
                                    <div className="d-flex justify-content-center flex-row" style={{ objectFit: 'contain' }}>
                                        {Object.entries(JSON.parse(e)).map((category) => (<p><span>{category[0]}&nbsp;&nbsp;</span><span>{category[1]}</span></p>))}
                                    </div>
                                </td>

                            })}
                        </tr> */}
                    </table>
                </div>
            </div >

        </>
    );
};

export default DailyReportDetail;