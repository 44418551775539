import { useState } from 'react';
import B2GNavbar from '../../features/navigation_bar/b2gNavigation/b2gNavbar';
import B2GSidebar from '../../features/navigation_bar/b2gNavigation/b2gSideBar'
import { Outlet } from 'react-router-dom';

const LayoutB2g = () => {
    const [sidebarbuttonData, setSidebarbuttonData] = useState(false);
    return (
        // <div className='App-b2g'>
        //     <B2GNavbar />
        //     <B2GSidebar />
        //     {/* <main className='main-b2g' > */}
        //     <main  >
        //         <Outlet />
        //     </main>
        //     {/* </main> */}

        // </div>
        <>
            <B2GNavbar sidebarbuttonData={sidebarbuttonData} setSidebarbuttonData={setSidebarbuttonData} />
            <div className='d-flex'>
                {sidebarbuttonData && <div style={{ width: "180px" }} >
                    <B2GSidebar />
                </div>}
                <main className='main_box' style={{ width: `${sidebarbuttonData ? '90vw' : '100vw'}` }}  >
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default LayoutB2g
