import React from "react";
import { NavLink } from "react-router-dom";

const SidebarLink = ({ to, icon, text }) => (
    <NavLink className={({ isActive }) => isActive ? "active active-transition font-bold nav-link select2" : "font-thin nav-link select2 active"} to={to}>
        <li className="nav-item select  mb-1 ms-3">
            <span className="sidebar_text">
                <i className={`fa-solid fa-${icon}`}></i> &nbsp; {text}
            </span>
        </li>
    </NavLink>
);
const Dropdown = ({ text, navLinkname, to }) => (
    <li className="nav-item select mt-3   ms-4 ml-1 ">
        <div className="dropdown-toggle text-white ms-1" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="sidebar_text">
                {text}
            </span>
        </div>
        <ul className="dropdown-menu menu-side text-start p-1" aria-labelledby="dropdownMenuButton1">
            {navLinkname.map((e, i) => {
                return <NavLink key={i} className={({ isActive }) => isActive ? "active-transition font-bold dropdown-item" : "font-thin dropdown-item"} to={to[i]}>
                    <li className=" link-item ">
                        {e}
                    </li>
                </NavLink>
            })}

        </ul>
    </li>
);
const B2GSidebar = () => {

    return (
        <>
            <nav id="sidebar" className="sidebarsidebar bg-left-navabr">
                <ul className="navbar-nav scrollBar ms-auto mb-lg-0 d-flex pb-4 flex-column text-left mt-1">
                    <SidebarLink to="/" className="mt-2" text="Dashboard" />
                    <SidebarLink to="/b2g/states" text="States" />
                    <SidebarLink to="/b2g/districts" text="Districts" />
                    <SidebarLink to="/b2g/communities" text="Community's" />
                    <SidebarLink to="/b2g/wards" text="Wards" />
                    <SidebarLink to="/b2g/survey" text="Survey" />
                    <SidebarLink to="/b2g/driverLive" text="Driver live" />
                    <SidebarLink to="/b2g/dailyreport" text="Daily Report" />
                    <SidebarLink to="/b2g/collections" text="Collection" />
                    <SidebarLink to="/b2g/complains" text="Complain" />
                    <SidebarLink to="/b2g/admin" text="Admin" />
                    <SidebarLink to="/b2g/iecteam" text="IEC Team" />
                    <Dropdown to={['/b2g/CollectionDriver', '/b2g/vehicle', '/b2g/supervisorList', '/b2g/CollectionIEC_Person', '/b2g/HelperList']} navLinkname={['Driver', 'Vehicle', 'Supervisor', 'IEC-Person', 'Helper']} text="Members" />
                    <Dropdown to={['/b2g/waste', '/b2g/category']} navLinkname={['Waste', 'Category']} text="Waste" />

                </ul>
            </nav>
        </>
    )
}

export default B2GSidebar;