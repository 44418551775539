
import B2GBreadcrumb from "../../components/breadcrumb/B2GBreadcrumb";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
import { useEffect, useState } from "react";
import { useGetIecTeamQuery } from "./iecTeamSlice";
import { useNavigate } from "react-router-dom";

const IECTeamList = () => {
    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('dailyReportPage');
        return savedPage ? Number(savedPage) : 1;
    })
    useEffect(() => {
        localStorage.setItem('dailyReportPage', page);
    }, [page]);
    const navigate = useNavigate();

    const {
        data: iecTeam,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetIecTeamQuery({ page })

    let totalPages = 0;

    if (iecTeam && iecTeam.entities) {
        totalPages = iecTeam.totalPage;
    }

    const pageMin = () => setPage((prev) => Math.max(prev - 1, 1));
    const pageAdd = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const firstPage = () => setPage(1);
    const lastPage = () => setPage(totalPages);

    return (
        <>
            {isLoading && <SpinnerForPage />}
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-6 text-left">
                        <h3 className="page-title">IEC Team</h3>
                        <ul className="breadcrumb">
                            <B2GBreadcrumb />

                        </ul>
                    </div>
                    <div className="col-md-6 text-right">

                    </div>
                </div>

                <div className="card card-dark sidebarhome" style={{ height: '58vh' }}>
                    <div className="table-border-style" >
                        <div className="table-responsive"  >
                            <table className="table table-striped">
                                <thead>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {iecTeam?.entities?.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index} className="listData text-left fw-normal" onClick={() => { navigate(`/b2g/iecteamdetail/${e.id}/${page}/${index}`); }}>
                                                    <td>{index + 1 * (page * 10 - 9)}</td>
                                                    <td>{e.name}</td>
                                                    <td>{e.number}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="pagination">
                            <button className="page-link" onClick={firstPage}>
                                1
                            </button>
                            <button className="page-link" disabled={page <= 1} onClick={pageMin}>
                                &laquo; Previous
                            </button>
                            <div className="page-link">{page}</div>
                            <button className="page-link" disabled={page >= totalPages} onClick={pageAdd}>
                                Next &raquo;
                            </button>
                            <button className="page-link" onClick={lastPage}>
                                {totalPages}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IECTeamList;