import React, { useState } from 'react';
import { useGetDriversQuery } from "./driverSlice";
import DriverExcerpt from './DriverExcerpt';
import SpinnerForPage from '../../components/spinner/SpinnerForPage';


const CollectionDriver = () => {
    const {
        data: drivers,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetDriversQuery('getDrivers')

    console.log(drivers, "drivers");

    const [filterSearch, setFilterSearch] = useState({})
    const [filterDriver, setFilterDriver] = useState()

    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }

    const filterSearchData = () => {
        const filteredData = drivers?.ids?.filter((supervisor_id) => {
            const supervisorEntity = drivers?.entities[supervisor_id];
            return (
                (!filterSearch?.role_type || supervisorEntity?.role_type?.includes(filterSearch?.role_type)) &&
                (!filterSearch?.number || supervisorEntity?.number?.includes(filterSearch?.number)) &&
                (!filterSearch?.name || supervisorEntity?.name?.toLowerCase().includes(filterSearch?.name?.toLowerCase()))
            );
        });
        setFilterDriver(filteredData);
    }
    console.log("Driver Error", error);


    return (
        <div className="container-fluid mt-5">
            {isLoading && <SpinnerForPage />}

            <div className="row">
                <div className="col-md-6 text-left">
                    <h3 className="page-title">B2g  Driver</h3>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <span className="breadcrumb-active">B2g Driver</span>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="w-100 d-flex justify-content-center gap-5">
                <input className="form-select w-25" onChange={searchHandle} name="name" type="text" placeholder="Search Name" />
                <input className="form-select w-25" onChange={searchHandle} name="number" type="Number" placeholder="Search Number" />
                <input className="form-select w-25" onChange={searchHandle} name="role_type" type="name" placeholder="Role" />
                <button className="bg-left-navabr  text-light  btn btn-secondary" onClick={filterSearchData}>Data Search</button>
            </div>


            <div className="card card-dark mt-4">
                <div className="table-border-style">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr className="text-left">
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col"> Number</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Leave</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filterDriver?.map((driverId, index) => {
                                    return <DriverExcerpt driverId={driverId} index={index} />
                                })
                                }
                                {filterDriver == null &&
                                    drivers?.ids?.map((driverId, index) => {
                                        return <DriverExcerpt driverId={driverId} index={index} />
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionDriver;
