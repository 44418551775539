import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import secureLocalStorage from "react-secure-storage";
import DarkMode from "../../../../pages/darkmode/DarkMode";
import Mess from "../../../../pages/Mess";
import AdminUpdateInformation from "./AdminUpdateInformation";
import AdminUpdatePassword from "./AdminUpdatePassword";
import { logout } from '../../../auth/authSlice'
import { useDispatch } from "react-redux";
const AdminNavbar = ({ urlMain, urlGov, token, role }) => {

    const [employeeName, setEmployeeName] = useState("");
    const [roleName, setRoleName] = useState("");
    const [name, setName] = useState("");
    const [roleId, setRoleId] = useState("");
    const refOpenUpdate = useRef(null);
    const refOpenInformation = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logout())
    };

    const updatePassword = () => refOpenUpdate.current.click();
    const updateInformation = () => refOpenInformation.current.click();

    const passTheName = async () => {
        try {
            const response = await fetch(`${urlMain}/permissions`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setEmployeeName(data.data[0]?.EmployeeName);
                setRoleName(data.data[0]?.RoleName);
            } else if (data.statusCode === 401) {
                dispatch(logout())
            }
        } catch (error) {
            console.error("Error in pass the name:", error);
        }
    };

    const passTheUsers = async () => {
        try {
            const response = await fetch(`${urlGov}/user`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = await response.json();
            if (data.status === true) {
                setName(data.data[0]?.name);
                setRoleId(data.data[0]?.RoleId);
            }

        } catch (error) {
            console.error("Error in pass the users:", error);
        }
    };

    useEffect(() => {
        role === 6 ? passTheUsers() : passTheName();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Mess />
            <nav className={`b2b-navbar navbar navbar-expand-lg ${secureLocalStorage.getItem("selectedTheme") === "dark" ? "bg-top-navbar-dark" : "bg-top-navbar-light"}`}>
                <div className="container-fluid">
                    <img src="assets/images/ecowrap-logo.png" style={{ width: "180px" }} alt="ecowrap-logo" />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className="d-flex">
                            <ul className="navbar-nav mb-2 mb-lg-0 nav nav-pills">
                                <li className="nav-item ms-2 me-2">
                                    <Avatar round={true} size="35" name={role === 6 ? (name || "Not Available") : (employeeName || "Unknown Person")} />
                                </li>
                                <div className="dropdown">
                                    <div className="nav-item dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="text-left">
                                            <span className="fw-bold">{role === 6 ? (name || "Not Available") : (employeeName || "Unknown Person")}</span>
                                        </div>
                                        <span className="fw-lighter">{role === 6 ? `(${roleId === 6 ? "Government" : ""})` : `(${roleName})`}</span>
                                    </div>
                                    <ul className="dropdown-menu">
                                        {
                                            role === 6 ? (
                                                <>
                                                    <li>
                                                        <button className="dropdown-item" onClick={updateInformation}>
                                                            <i className="fa-solid fa-user"></i> &nbsp; Update Information
                                                        </button>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li>
                                                        <button className="dropdown-item" onClick={() => navigate("/main:profile")}>
                                                            <i className="fa-solid fa-user"></i> &nbsp; Profile
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item" onClick={updatePassword}>
                                                            <i className="fa-solid fa-lock"></i> &nbsp; Update Password
                                                        </button>
                                                    </li>
                                                </>
                                            )
                                        }
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                <i className="fa-solid fa-right-from-bracket"></i> &nbsp; Logout
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <li className="nav-item ms-2 me-2">
                                    <DarkMode />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <AdminUpdateInformation urlGov={urlGov} token={token} refOpenInformation={refOpenInformation} />
            <AdminUpdatePassword urlMain={urlMain} refOpenUpdate={refOpenUpdate} />
        </>
    )
}

export default AdminNavbar;