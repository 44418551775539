import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AddWasteCategoryOffCanvas from "./AddWasteCategoryOffCanvas";
import WasteCategoryExcerpt from "./WasteCategoryExcerpt";
import EditWasteCategoryOffCanvas from "./EditWasteCategoryOffCanvas";
import { useGetWasteCategoryQuery } from "./categorySlice";
import SpinnerForPage from "../../../components/spinner/SpinnerForPage";

const WasteCategoryList = () => {

    const refOpen = useRef(null)
    const {
        data: category,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetWasteCategoryQuery('getWasteCategory')
    const [status, setStatus] = useState("");
    const [stateEditWasteCategory, setStateEditWasteCategory] = useState()

    const handleReset = () => window.location.reload();

    const editWasteCategory = (wc_id) => {
        console.log(wc_id);
        const wcData = category?.entities[wc_id];
        refOpen.current.click()
        setStateEditWasteCategory({ wc_name: wcData.wc_name, wc_id: wc_id, wc_active: wcData.wc_active })
        console.log({ wc_name: wcData.wc_name, wc_id: wc_id, wc_active: wcData.wc_active });


    }
    console.log(stateEditWasteCategory, 'stateEditWasteCategory');


    return (
        <>
            {isLoading && <SpinnerForPage />}

            {/* {!isError &&  */}
            <div className="container-fluid mt-5">
                <div className="d-flex justify-content-between">
                    <div className="col-md-6  text-left">
                        <h3 className="heading_text">Category List</h3>
                        <ul className="breadcrumb">
                            {/* <MainBreadcrumb /> */}

                        </ul>
                    </div>
                    <div className="col-md-6 text-right ">
                        <AddWasteCategoryOffCanvas />
                    </div>
                </div>
                <form className="search_button">
                    <div className="row mt-4">

                        <div style={{ height: "40px" }} className="col-md-12  d-flex justify-content-center gap-2">


                            <select className="form-select w-25 " value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="0">Active</option>
                                <option value="1">Inactive</option>
                            </select>

                            <button type="button" className="bg-left-navabr  text-light  btn btn-secondary" >
                                Search
                            </button>
                            <button type="reset" className="bg-left-navabr  text-light  btn btn-secondary" onClick={handleReset}>
                                Reset
                            </button>
                        </div>

                    </div>
                </form>
                <div className="card card-dark mt-4">
                    {

                        (
                            <div className="table-border-style">
                                <div className="table-responsive sidebarhome ">
                                    <table className="table table-striped">
                                        <thead className="search_button">
                                            <tr className="text-center">
                                                <th scope="col">Sr. No.</th>
                                                <th scope="col">Category Name</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Create Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                category?.ids?.map((id, index) => {
                                                    return (
                                                        <WasteCategoryExcerpt key={index} editWasteCategory={editWasteCategory} id={id} index={index} />
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            {/*  } */}
            {/* {isError && <div>Something went wrong</div>} */}
            <EditWasteCategoryOffCanvas refOpen={refOpen} setStateEditWasteCategory={setStateEditWasteCategory} stateEditWasteCategory={stateEditWasteCategory} />


        </>
    )
}

export default WasteCategoryList;

