import { useCollectionController } from "./collectionController";
import { useState } from "react";
import moment from "moment";
import SpinnerForPage from "../../components/spinner/SpinnerForPage";
export default function CollectionList() {

    let totalPages = 1;
    let totalCollection = 0;
    const { page,
        setPage,
        collectionList,
        isErrorCollectionList,
        errorCollectionList,
        isLoadingCollectionList,

        handleNavigate
    } = useCollectionController()


    if (collectionList && collectionList?.entities && collectionList?.totalCollections && collectionList?.totalPages) {
        totalPages = collectionList.totalPages
        totalCollection = collectionList.totalCollections
    }

    const pageMin = () => setPage((prev) => Math.max(prev - 1, 1));
    const pageAdd = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const firstPage = () => setPage(1);
    const lastPage = () => setPage(totalPages);
    // console.log('collectionList', collectionList);

    return (
        <div className=" container-fluid p-3">
            <div className="row ">
                <h4 className="col-11 col-sm-8 col-md-6 heading_text"> Daily Collection</h4>
            </div>
            <div className=" border row d-flex justify-content-center">
                <div className="search_button col-11 sidebarhome" style={{ height: '68vh' }}>
                    <table className=" table table-striped  ">
                        <thead >
                            <tr>
                                <th>#</th>
                                <th>Area</th>
                                <th>Collection type</th>
                                <th>Collection status</th>
                                <th>Collection date</th>
                                <th>Driver</th>
                                <th>Helper</th>
                            </tr>
                        </thead>
                        <tbody    >
                            {!isLoadingCollectionList && !isErrorCollectionList && collectionList?.entities && Object.values(collectionList?.entities).map((item, index) => (
                                <tr className="listData" key={index} onClick={() => (handleNavigate(`/b2g/collections/${item.assignment_id}`))}>
                                    <td>{index + 1 * (page * 10 - 9)}</td>
                                    <td>{item.area_name}</td>
                                    <td>{item.category}</td>
                                    <td>{item.status}</td>
                                    <td>{moment(item.collection_date).format('YYYY-MM-DD')}</td>
                                    <td>{item.p_name}</td>
                                    <td>{item.helper_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="daily_collection">
                    {!isLoadingCollectionList && !isErrorCollectionList && collectionList?.entities && Object.values(collectionList?.entities).map((item, index) => (
                        <div key={index} className="Card_table_data " onClick={() => (handleNavigate(`/b2g/collections/${item.assignment_id}`))}>
                            <div div className="Card_data">
                                <span className="Card_srNo">#</span>
                                <span className="Card_data_list">{index + 1 * (page * 10 - 9)}</span>
                            </div>
                            <div className="Card">
                                <div className="Card_data">
                                    <span className="Card_srNo">Area :-</span>
                                    <span className="Card_data_list">{item.area_name}</span>
                                </div>
                                <div className="Card_data">
                                    <span className="Card_srNo">Collection type :-</span>
                                    <span className="Card_data_list">{item.category}</span>
                                </div>
                            </div>
                            <div className="Card">
                                <div className="Card_data">
                                    <span className="Card_srNo">Collection :-</span>
                                    <span className="Card_data_list">{item.status}</span>
                                </div>
                                <div className="Card_data">
                                    <span className="Card_srNo">Collection :-</span>
                                    <span className="Card_data_list">{moment(item.collection_date).format('YYYY-MM-DD')}</span>
                                </div>
                            </div>
                            <div className="Card">
                                <div className="Card_data">
                                    <span className="Card_srNo">Driver :-</span>
                                    <span className="Card_data_list">{item.p_name}</span>
                                </div>
                                <div className="Card_data">
                                    <span className="Card_srNo">Helper :-</span>
                                    <span className="Card_data_list">{moment(item.collection_date).format('YYYY-MM-DD')}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="pagination_box fixed-bottom mt-3 mb-3">
                <div className=" d-flex justify-content-center">
                    <div className="pagination">
                        <button className="page-link" onClick={firstPage}>
                            1
                        </button>
                        <button className="page-link d-flex" disabled={page <= 1} onClick={pageMin}>
                            &laquo; Previous
                        </button>
                        <div className="page-link">{page}</div>
                        <button className="page-link" disabled={page >= totalPages} onClick={pageAdd}>
                            Next &raquo;
                        </button>
                        <button className="page-link" onClick={lastPage}>
                            {totalPages}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}