import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { useGetCollectionDetailsQuery } from './collectionRtk';
import '../driverLive/liveDriver.css';
import moment from 'moment';
import layers from '../../images/layers.png'

const DEFAULT_CENTER = { lat: 26.8954906, lng: 75.839768 };
const DEFAULT_ZOOM = 10;
const MAP_API_KEY = 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8';

const CollectionRoute = ({ id, collection_date, boundry, start_location, end_location }) => {
    const [mapCenter, setMapCenter] = useState(DEFAULT_CENTER);
    const [mapInstance, setMapInstance] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [boundary, setBoundary] = useState([]);
    const [path, setPath] = useState([]);
    const [isAnimating, setIsAnimating] = useState(false);
    const [animationIntervalId, setAnimationIntervalId] = useState(null);
    const [truckMarker, setTruckMarker] = useState(null);
    const [tooltipData, setTooltipData] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [mapType, setMapType] = useState("ROADMAP");

    const location = useLocation();
    const {
        data: collectionDetails,
        isLoading: isCollectionDetailsLoading,
        isError: isCollectionDetailsError,
    } = useGetCollectionDetailsQuery({
        assignment_id: id,
        collection_date: collection_date,
    });

    useEffect(() => {
        if (!isCollectionDetailsLoading && !isCollectionDetailsError && collectionDetails) {
            setPath(collectionDetails);
            setBoundary(boundry[0]);
        }
    }, [isCollectionDetailsLoading, isCollectionDetailsError, collectionDetails, location]);

    useEffect(() => {
        if (mapInstance && googleMaps && boundary.length > 0) {
            const polygon = new googleMaps.Polygon({
                paths: boundary.map(point => ({ lat: point.x, lng: point.y })),
                strokeColor: '#505050',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#505050',
                fillOpacity: 0.1,
                map: mapInstance,
            });

            const bounds = new googleMaps.LatLngBounds();
            boundary.forEach(point => bounds.extend(new googleMaps.LatLng(point.lat, point.lng)));
            mapInstance.fitBounds(bounds);

            const listener = googleMaps.event.addListenerOnce(mapInstance, 'bounds_changed', () => {
                if (mapInstance.getZoom() > 16) {
                    mapInstance.setZoom(16);
                }
            });
            return () => googleMaps.event.removeListener(listener);
        }
    }, [boundary, mapInstance, googleMaps]);

    useEffect(() => {
        if (mapInstance && googleMaps && path.length > 0) {
            const polyline = new googleMaps.Polyline({
                path: path.map(point => ({ lat: point.x, lng: point.y })),
                strokeColor: '#0000FF',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                map: mapInstance,
            });

            polyline.addListener('mouseover', (e) => {
                showTimeTooltip(e.latLng);
            });

            polyline.addListener('mouseout', () => {
                setTooltipData(null);
                setTooltipPosition(null);
            });

            const bounds = new googleMaps.LatLngBounds();
            path.forEach(point => bounds.extend(new googleMaps.LatLng(point.x, point.y)));
            mapInstance.fitBounds(bounds);
        }
    }, [path, mapInstance, googleMaps]);

    function showTimeTooltip(latLng) {
        const nearestPoint = findNearestPoint(latLng);
        const formattedTime = moment(nearestPoint.timeStamp).format('YYYY-MM-DD HH:mm:ss');
        setTooltipData(formattedTime);
        setTooltipPosition({ lat: latLng.lat(), lng: latLng.lng() });
    }

    function findNearestPoint(latLng) {
        let nearestPoint = collectionDetails[0];
        let minDistance = window.google.maps.geometry.spherical.computeDistanceBetween(latLng, new window.google.maps.LatLng(collectionDetails[0].x, collectionDetails[0].y));

        collectionDetails.forEach(point => {
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(latLng, new window.google.maps.LatLng(point.x, point.y));
            if (distance < minDistance) {
                minDistance = distance;
                nearestPoint = point;
            }
        });

        return nearestPoint;
    }

    const animateTruck = () => {
        if (!isAnimating && truckMarker && path.length > 0) {
            let index = 0;
            const totalPoints = path.length;

            const intervalId = setInterval(() => {
                if (index < totalPoints) {
                    const currentPoint = path[index];
                    const nextPoint = path[index + 1] || currentPoint;

                    const latLng = new googleMaps.LatLng(currentPoint.x, currentPoint.y);
                    const heading = googleMaps.geometry.spherical.computeHeading(
                        new googleMaps.LatLng(currentPoint.x, currentPoint.y),
                        new googleMaps.LatLng(nextPoint.x, nextPoint.y)
                    );

                    truckMarker.setPosition(latLng);
                    truckMarker.setIcon({
                        url: getTruckIconUrl(heading),
                        scaledSize: new googleMaps.Size(40, 40),
                        anchor: new googleMaps.Point(20, 20),
                    });

                    index += 2;
                } else {
                    clearInterval(intervalId);
                    setIsAnimating(false);
                    setAnimationIntervalId(null);
                }
            }, 1000);
            setAnimationIntervalId(intervalId);
        }
    };

    useEffect(() => {
        if (mapInstance && googleMaps && path.length > 0) {
            const marker = new googleMaps.Marker({
                position: { lat: path[0].x, lng: path[0].y },
                map: mapInstance,
                icon: {
                    url: getTruckIconUrl(),
                    scaledSize: new googleMaps.Size(40, 40),
                    anchor: new googleMaps.Point(20, 20),
                },
            });
            setTruckMarker(marker);
        }
    }, [path, mapInstance, googleMaps]);

    useEffect(() => {
        if (mapInstance && googleMaps) {
            new googleMaps.Marker({
                position: { lat: start_location.lat, lng: start_location.lng },
                map: mapInstance,
                label: 'S',
                icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    scaledSize: new googleMaps.Size(40, 40),
                },
            });

            new googleMaps.Marker({
                position: { lat: end_location.lat, lng: end_location.lng },
                map: mapInstance,
                label: 'E',
                icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                    scaledSize: new googleMaps.Size(40, 40),
                },
            });
        }
    }, [mapInstance, googleMaps, start_location, end_location]);

    const getTruckIconUrl = (heading = 0) => {
        const color = '#4CAF50';
        const iconSvg = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200" width="100" height="200" transform="rotate(${heading})">
            <rect x="10" y="40" width="80" height="120" fill="${color}" stroke="black" stroke-width="2" />
            <rect x="20" y="10" width="60" height="30" fill="#333" stroke="black" stroke-width="2"/>
            <circle cx="20" cy="170" r="10" fill="black" />
            <circle cx="80" cy="170" r="10" fill="black" />
          </svg>`;
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;
    };

    const handleToggleAnimation = () => {
        if (isAnimating) {
            clearInterval(animationIntervalId);
            setIsAnimating(false);
            setAnimationIntervalId(null);
        } else {
            setIsAnimating(true);
            animateTruck();
        }
    };

    const handleMouseMove = (e) => {
        if (tooltipData) {
            setCursorPos({ x: e.clientX, y: e.clientY });
        }
    };

    const mapView = () => {
        setMapType((e) => (e === "ROADMAP" ? "SATELLITE" : "ROADMAP"));
    };
    const createMapOptions = (maps) => {
        return { mapTypeId: maps.MapTypeId[mapType] }
    }
    return (
        <div className="container-fluid map_box" onMouseMove={handleMouseMove}>
            <div className="row mt-4">
                <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
                    <h3>Live Location Map</h3>
                    {/* <button className="btn btn-primary mb-2" onClick={mapView}>Map View</button> */}

                    <button className="button_all btn" onClick={handleToggleAnimation}>
                        {isAnimating ? 'Stop Animation' : 'Start Animation'}
                    </button>
                </div>

                <div className="col-md-12" style={{ height: '80vh', width: '100%', position: 'relative' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: MAP_API_KEY }}
                        defaultCenter={DEFAULT_CENTER}
                        defaultZoom={DEFAULT_ZOOM}
                        yesIWantToUseGoogleMapApiInternals
                        center={mapCenter}
                        zoom={12}
                        onGoogleApiLoaded={({ map, maps }) => {
                            setMapInstance(map);
                            setGoogleMaps(maps);
                        }}
                        options={createMapOptions}
                    // options={{
                    //     minZoom: 5,
                    //     maxZoom: 100,
                    // }}
                    >
                        <div style={{
                            position: 'fixed',
                            top: `150px`,
                            left: `-550px`,
                        }}>
                            {<img style={{ width: "50px", height: "50px" }} src={layers} alt='Satellite' onClick={mapView} />}
                        </div>
                    </GoogleMapReact>

                    {tooltipData && tooltipPosition && (
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: `${cursorPos.y - 150}px`,
                                    left: `${cursorPos.x}px`,
                                    backgroundColor: 'white',
                                    padding: '2vw',
                                    border: '1px solid black',
                                    borderRadius: '5px',
                                    transform: 'translate(-50%, -100%)',
                                    zIndex: 1000,
                                }}
                            >
                                {tooltipData}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div >
    );
};

export default CollectionRoute;
