import React, { useState } from 'react';
import IecExcerpt from './iecExcerpt';
import SpinnerForPage from '../../components/spinner/SpinnerForPage';
import AddIECPersonOffCanavas from './addIECPerson';
import { useGetIECPersonQuery } from './iecPersonSlice';

const CollectionIEC_Person = () => {
    const {
        data: iec,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetIECPersonQuery('getIECPerson')


    const [filterSearch, setFilterSearch] = useState({})
    const [filterien, setFilterien] = useState()

    const searchHandle = (e) => {
        setFilterSearch({ ...filterSearch, [e.target.name]: e.target.value })
    }
    const filterData = () => {
        const filteredData = iec?.ids?.filter((inc_id) => {
            const districtEntity = iec?.entities[inc_id];
            return (
                (!filterSearch?.inc_number || districtEntity?.inc_number?.toLowerCase().includes(filterSearch?.inc_number.toLowerCase()))
                &&
                (!filterSearch?.inc_name || districtEntity?.inc_name?.toLowerCase().includes(filterSearch?.inc_name?.toLowerCase()))

            );
        });

        setFilterien(filteredData);
    };
    return (
        <div>
            {isLoading && <SpinnerForPage />}

            <div className="container-fluid mt-5">
                <div className="d-flex justify-content-between">
                    <div className="col-md-6 text-left">
                        <h3 className="heading_text page-title">B2g  Iec</h3>

                    </div>
                    <div className="col-md-6 text-right">
                        <AddIECPersonOffCanavas />

                    </div>
                </div>
                <div className="search_button w-100 d-flex justify-content-center gap-5">
                    <input className="form-select w-25" onChange={searchHandle} name="inc_name" type="text" placeholder="Search  Name" />
                    <input className="form-select w-25" onChange={searchHandle} name="inc_number" type="text" placeholder="Search  Number" />
                    <button className="btn btn-secondary bg-left-navabr" onClick={filterData}>dataSearch</button>
                </div>

                <div className="card card-dark mt-4 sidebarhome">
                    <div className="table-border-style">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className='search_button'>
                                    <tr className="text-left">
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> Number</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        filterien?.map((incID, index) => {
                                            return <IecExcerpt key={index} incID={incID} index={index} />
                                        })
                                    }

                                    {filterien == null &&
                                        iec?.ids?.map((incID, index) => {
                                            return <IecExcerpt key={index} incID={incID} index={index} />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionIEC_Person;
